import React from 'react'
import { Heading, List, Anchor, Text } from 'grommet'
import { MailOption, Phone } from 'grommet-icons'
import styled from 'styled-components'

import { Layout } from '../layout'
import SEO from '../components/seo'

const Root = styled.section`
  max-width: 1000px;
  align-self: center;
`
const Grid = styled.div`
  width: 100%;
  display: grid;
  gap: 2px 5px;
  grid-template-columns: 5fr 3fr 1fr;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const NameLink = styled(Anchor)`
  font-weight: 400;
  color: ${p => p.theme.colors.body};
  &:hover {
    text-decoration-color: ${p => p.theme.colors.body};
  }
`

const teamData = [
  {
    title: 'Prof. dr hab.',
    name: 'Ewa Sawosz Chwalibóg',
    mail: 'ewa_sawosz_chwalibog@sggw.edu.pl',
    phone: 225936660,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS5a0d66bd967a4ad9b6045e115dea9b6a&affil=&lang=pl',
  },
  {
    title: 'Dr hab.',
    name: 'Marta Grodzik, prof. SGGW',
    mail: 'marta_grodzik@sggw.edu.pl',
    phone: 225936673,
    link:
    'https://bw.sggw.edu.pl/info.seam?id=WULSfcdb1c38ca1a4be4a914cb88ec171ae9&affil=&lang=pl',
  },
  {
    title: 'Dr hab.',
    name: 'Patryk Krzemiński',
    mail: 'patryk_krzeminski@sggw.edu.pl',
    phone: 225936664,
    link:
      'https://bw.sggw.edu.pl/info/author/WULS03f2abdcf6014140aaf2bcb9727bb1d0/Patryk+Krzemi%25C5%2584ski?affil=&tab=&lang=pl',
  },
  {
    title: 'Dr hab.',
    name: 'Mateusz Wierzbicki, prof. SGGW',
    mail: 'mateusz_wierzbicki@sggw.edu.pl',
    phone: 225936676,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS8fc64c3b2c5c4535b10ac795cf4ec482&affil=&lang=pl',
  },
  {
    title: 'Dr',
    name: 'Anna Hotowy',
    mail: 'anna_hotowy@sggw.edu.pl',
    phone: 225936670,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS818da8adeb464d56bb48deb9fd84247f&affil=&lang=pl',
  },
  {
    title: 'Dr',
    name: 'Marta Kutwin',
    mail: 'marta_prasek@sggw.edu.pl',
    phone: 225936671,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS18eab1a5731542c4a483fe5e79122e88&affil=&lang=pl',
  },
  {
    title: 'Dr',
    name: 'Magdalena Matusiewicz',
    mail: 'magdalena_matusiewicz@sggw.edu.pl',
    phone: 225936661,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS7b0b3274b3d54532b4b1ca8961a0695f&affil=&lang=pl',
    },
    {
      title: 'Dr inż.',
      name: 'Agnieszka Ostrowska',
      mail: 'agnieszka_ostrowska@sggw.edu.pl',
      phone: 225935820,
      link: 'https://bw.sggw.edu.pl/info/author/WULS6addbb3eb25f4bbf84f2a925df5681b8/'
    },
    {
      title: 'Dr',
      name: 'Malwina Sosnowska-Ławnicka',
      mail: 'malwina_sosnowska@sggw.edu.pl',
      phone: 225935828,
      link: 'https://bw.sggw.edu.pl/info/author/WULSdcb22e1818884bbdae6a6012ce9b13f2/'
    },
  {
    title: 'Dr',
    name: 'Barbara Strojny-Cieślak',
    mail: 'barbara_strojny@sggw.edu.pl',
    phone: 225936671,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS9de7c22912fe4b3a8c72bf4e83053c4c&affil=&lang=pl',
  },
  {
    title: 'Dr inż.',
    name: 'Marlena Zielińska-Górska',
    mail: 'marlena_zielinska_gorska@sggw.edu.pl',
    phone: 225936665,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS5106a4d62a6342979b07772de24d8ee1&affil=&lang=pl',
  },
  {
    title: 'Dr',
    name: 'Karolina Haziak',
    mail: 'karolina_haziak@sggw.edu.pl',
    phone: 225936670,
  },
]

const phdStudents = [
  {
    title: 'Mgr',
    name: 'Jaśmina Bałaban',
    mail: 'jasmina_balaban@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr inż.',
    name: 'Karolina Daniluk',
    mail: 'karolina_daniluk@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr',
    name: 'Wiktoria Frączek',
    mail: 'wiktoria_fraczek@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr inż.',
    name: 'Agata Lange',
    mail: 'agata_lange@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr',
    name: 'Michał Pruchniewski',
    mail: 'michal_pruchniewski@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr',
    name: 'Jarosław Szczepaniak		',
    mail: 'jaroslaw_szczepaniak@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr',
    name: 'Barbara Wójcik		',
    mail: 'barbara_wojcik@sggw.edu.pl',
    phone: 225936667,
  },
  {
    title: 'Mgr',
    name: 'Katarzyna Zawadzka',
    mail: 'katarzyna_zawadzka@sggw.edu.pl',
    phone: 225936667,
  },
]



const makeList = data => (
  <List data={data} pad="small">
    {datum => (
      <Grid columns={{ count: 3, size: 'auto' }}>
        {datum.link ? (
          <NameLink
            href={datum.link}
            label={`${datum.title} ${datum.name}`}
            size="large"
            target="_blank"
          />
        ) : (
          <Text size="large">{`${datum.title} ${datum.name}`}</Text>
        )}
        <NameLink
          href={`mailto:${datum?.mail}`}
          icon={<MailOption color="brand" size="small" />}
          label={datum.mail}
          size="small"
        />
        {datum.phone && (
          <NameLink
            href={`tel:${datum.phone}`}
            icon={<Phone color="brand" size="small" />}
            label={datum.phone}
            size="small"
          />
        )}
      </Grid>
    )}
  </List>
)

const TeamPage = () => (
  <Layout>
    <SEO title="Zespół" />
    <Root>
      <Heading>Pracownicy</Heading>
      <Heading level={2}>Kierownik Katedry</Heading>
      {makeList([
        {
    title: 'Dr hab.',
    name: 'Sławomir Jaworski, prof. SGGW',
    mail: 'slawomir_jaworski@sggw.edu.pl',
    phone: 225936675,
    link:
      'https://bw.sggw.edu.pl/info.seam?id=WULS57d9b0d579c9492ba15608c3dfc84595&affil=&lang=pl',
  },
      ])}
      <Heading level={3} margin={{ top: 'medium', bottom: 'small' }}>
        Sekretariat
      </Heading>
      {makeList([
        {
          title: 'Mgr',
          name: 'Kamila Zielińska',
          mail: 'kamila_zielinska@sggw.edu.pl',
          phone: 225936669,
        },
      ])}
      <Heading level={3} margin={{ top: 'medium', bottom: 'small' }}>
        Zespół
      </Heading>
      {makeList(teamData)}
      <Heading level={3} margin={{ top: 'medium', bottom: 'small' }}>
        Doktoranci
      </Heading>
      {makeList(phdStudents)}
    </Root>
  </Layout>
)

export default TeamPage
